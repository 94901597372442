<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 11C15.5001 11.1819 15.456 11.3612 15.3716 11.5223C15.2871 11.6835 15.1649 11.8218 15.0153 11.9253L10.1403 15.3003C9.9715 15.4172 9.77392 15.4856 9.56898 15.4982C9.36405 15.5108 9.15957 15.467 8.97772 15.3717C8.79587 15.2764 8.64357 15.1331 8.53732 14.9574C8.43108 14.7817 8.37495 14.5803 8.375 14.375V7.625C8.37495 7.41968 8.43108 7.21826 8.53732 7.04256C8.64357 6.86686 8.79587 6.72359 8.97772 6.62827C9.15957 6.53295 9.36405 6.48922 9.56898 6.5018C9.77392 6.51439 9.9715 6.58283 10.1403 6.69969L15.0153 10.0747C15.1649 10.1782 15.2871 10.3165 15.3716 10.4777C15.456 10.6388 15.5001 10.8181 15.5 11ZM21.125 11C21.125 13.0025 20.5312 14.9601 19.4186 16.6251C18.3061 18.2902 16.7248 19.5879 14.8747 20.3543C13.0246 21.1206 10.9888 21.3211 9.02471 20.9305C7.06066 20.5398 5.25656 19.5755 3.84055 18.1595C2.42454 16.7435 1.46023 14.9393 1.06955 12.9753C0.678878 11.0112 0.879387 8.97543 1.64572 7.12533C2.41206 5.27523 3.70981 3.69392 5.37486 2.58137C7.0399 1.46882 8.99747 0.875 11 0.875C13.6844 0.877978 16.258 1.94567 18.1562 3.84383C20.0543 5.74199 21.122 8.3156 21.125 11ZM18.875 11C18.875 9.44247 18.4131 7.91992 17.5478 6.62488C16.6825 5.32985 15.4526 4.32049 14.0136 3.72445C12.5747 3.12841 10.9913 2.97246 9.46367 3.27632C7.93607 3.58017 6.53288 4.3302 5.43154 5.43153C4.3302 6.53287 3.58018 7.93606 3.27632 9.46366C2.97246 10.9913 3.12841 12.5747 3.72445 14.0136C4.32049 15.4526 5.32985 16.6825 6.62489 17.5478C7.91993 18.4131 9.44248 18.875 11 18.875C13.0879 18.8728 15.0896 18.0424 16.566 16.566C18.0424 15.0896 18.8728 13.0879 18.875 11Z"
      fill="#FF0F00"
    />
  </svg>
</template>

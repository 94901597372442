import ExcelExport from "@/services/exportFile/exportToExcel";
import printJS from "print-js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const downLoadExcel = async (
  selectedFileType,
  element,
  fileName,
  fileHeaderToExport,
  fileToExport
) => {
  if (selectedFileType === "pdf") {
    if (!element) {
      console.error("Element to print not found.");
      return;
    }

    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      const imgWidth = 8.5; // Page width in inches
      const pageHeight = 11; // Page height in inches
      const margin = 0.5; // Half-inch margin
      const contentHeight = pageHeight - margin * 2; // Height available for content
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(16);
      pdf.text(`${fileName}`, imgWidth / 2, margin, { align: "center" });

      // Clone the element to prevent affecting the DOM
      const clonedElement = element.cloneNode(true);

      // Apply consistent styles for neatness
      clonedElement.style.fontFamily = "Poppins";
      clonedElement.style.fontSize = "10px";
      clonedElement.style.color = "#333";
      clonedElement.style.borderCollapse = "collapse";

      // Ensure tables and rows maintain spacing and padding
      clonedElement.querySelectorAll("table").forEach((table) => {
        table.style.width = "100%";
        table.style.marginBottom = "1rem";
        table.style.border = "1px solid #ddd";
      });

      clonedElement.querySelectorAll("tr").forEach((row) => {
        row.style.borderBottom = "1px solid #ddd";
      });

      clonedElement.querySelectorAll("th, td").forEach((cell) => {
        cell.style.padding = "8px";
        cell.style.textAlign = "left";
      });

      // Split rows into pages
      const rows = Array.from(clonedElement.querySelectorAll("tr"));
      let currentPage = 0;

      while (rows.length > 0) {
        const tempTable = document.createElement("table");
        tempTable.style.borderCollapse = "collapse";
        tempTable.style.width = "100%";

        let currentHeight = 0;

        // Add rows to the current page until content height is exceeded
        while (rows.length > 0 && currentHeight < contentHeight) {
          const row = rows.shift();
          const clonedRow = row.cloneNode(true);
          tempTable.appendChild(clonedRow);

          document.body.appendChild(tempTable);
          const tempHeight = tempTable.offsetHeight / 96; // Convert px to inches
          document.body.removeChild(tempTable);

          if (tempHeight > contentHeight) {
            rows.unshift(row); // Put row back if it doesn't fit
            break;
          }

          currentHeight = tempHeight;
        }

        // Render page content
        document.body.appendChild(tempTable);
        const canvas = await html2canvas(tempTable, {
          scale: 1.5,
          useCORS: true,
        });
        document.body.removeChild(tempTable);

        const imgData = canvas.toDataURL("image/png");
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (currentPage > 0) pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          margin + 0.2,
          imgWidth - margin * 2,
          imgHeight
        );

        currentPage++;
      }

      pdf.save(`${fileName}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
    // var element = document.getElementById('element-to-print'); //fix
    // var opt = {
    //     // margin:       0.3,
    //     // padding:      1,
    //     filename: `${fileName}.pdf`,
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
    //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    // };

    // // New Promise-based usage:
    // html2pdf().set(opt).from(element).save();
    // // html2pdf(element);
  } else {
    const filterVal = fileHeaderToExport.map((i, index) => index);
    const list = fileToExport;
    const header = fileHeaderToExport;
    ExcelExport.exportFile(filterVal, list, header, fileName, selectedFileType);
  }
};

const tableHeaderToJson = (_th) => {
  let _arr = [].map
    .call(_th, function (th) {
      return th.innerHTML;
    })
    .join("|");
  let _data = _arr.split("|");
  return Array.from(_data);
};

const tableToJson = (_table) => {
  let _trLength = _table.getElementsByTagName("tr").length;
  let _jsonData = [];
  let _obj = {};

  let _htmlToJSON = function (index) {
    let _tr = _table.getElementsByTagName("tr")[index];
    let _td = _tr.getElementsByTagName("td");
    let _arr = [].map
      .call(_td, function (td) {
        return td.innerHTML;
      })
      .join("|");
    let _data = _arr.split("|");
    _obj = Object.assign({}, _data);
    _jsonData.push(_obj);
  };
  for (var i = 1; i < _trLength; i++) {
    _htmlToJSON(i);
  }
  return _jsonData;
};

export default {
  tableToJson,
  tableHeaderToJson,
  downLoadExcel,
  printJS,
};

<template>
  <div class="container-fluid">
    <!-- header area -->
    <div class="row flex-row justify-content-between">
      <div class="mb-4">
        <div class="text-head font-weight-bold h2 py-0 my-0 text-black">
          Income Statement Report
        </div>
        <!-- <div @click="goBack">
          <span class="s-18 fw-400 cursor-pointer text-black">
            <img src="../../../assets/goback.png" alt="" /> Go back</span
          >
        </div> -->
      </div>
      <div class="c-pointer">
        <el-dropdown trigger="click" class="w-100">
          <div class="d-flex default-btn text-dark w-100" size="large">
            <span class="mt-1 s-14 text-head" style="color: #0040ff"
              >Export</span
            >
            <div class="mt-0 ml-1">
              <el-icon :size="10" class="el-icon--right primary--text"
                ><ArrowDownBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(bookType, index) in bookTypeList"
                :key="index"
              >
                <a
                  class="no-decoration text-dark"
                  @click="downloadFile(bookType)"
                >
                  {{ bookType.name }}
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-12 px-0 s-18 fw-400 text-black">
        A detailed report of all incomes and expenses of the ministry in a given
        period.
      </div>
    </div> -->
    <!-- date area -->
    <div class="row bg-gray-300 justify-content-center py-4 border-radius-8">
      <div class="col-md-12">
        <div class="row align-items-center">
          <div class="col-md-8 col-12">
            <div class="row align-items-center">
              <div class="col-md-3 col-12">
                <div class="text-md-right text-left s-14 fw-400 text-dak">
                  Date Range
                </div>
              </div>
              <div class="col-md-3 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-md-1">
                  <el-date-picker
                    v-model="startDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
              <div class="col-md-3 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-md-1">
                  <el-date-picker
                    v-model="endDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="col-md-12 d-flex justify-content-end px-0">
              <el-button
                class="c-pointer w-100"
                :color="primarycolor"
                :loading="loading"
                round
                @click="generateReport"
              >
                Generate Report
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-4">
      <div class="col-md-12">
        <div class="text-center">
          <div class="row justify-content-center">
            <div
              class="col-md-10 d-flex justify-content-between align-items-end"
            >
              <div class="fw-400 s-14 text-dak">
                <span>Income</span>
                <div class="s-24 font-weight-600 text-head text-dak">
                  {{ currentUser.currencySymbol }}
                  {{ Math.abs(totalIncomes).toLocaleString() }}.00
                </div>
              </div>
              <span>-</span>
              <div class="fw-400 s-14 text-dak">
                <span>Expenses</span>
                <div class="s-24 font-weight-600 text-head text-dak">
                  {{ currentUser.currencySymbol }}
                  {{ Math.abs(totalExpenses).toLocaleString() }}.00
                </div>
              </div>
              <span>=</span>
              <div class="fw-400 s-14 text-dak">
                <span>Net Profit</span>
                <div
                  style="color: #088c35"
                  class="s-24 text-head font-weight-600"
                >
                  {{ currentUser.currencySymbol }}
                  {{ Math.abs(diffBtwIncomeAndExpenses).toLocaleString() }}.00
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5 cursor-pointer">
          <div
            style="background: #e0f2ff"
            class="p-1 d-flex align-items-center border-radius-8"
          >
            <div
              style="color: #08162e"
              class="s-12 px-3"
              :class="{ active: selectedTab === 'summary' }"
              @click="selectedTab = 'summary'"
            >
              Summary
            </div>
            <div
              style="color: #08162e"
              class="s-12 px-3"
              :class="{ active: selectedTab === 'details' }"
              @click="selectedTab = 'details'"
            >
              Details
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!--end of date area -->
  <div id="element-to-print">
    <div>
      <div
        class="container-fluid d-flex justify-content-center my-2"
        v-if="displayTitle"
      >
        <div class="text-head font-weight-bold h2">Income Statement Report</div>
      </div>
      <section
        class="container-fluid"
        :class="
          incomeStatement && incomeStatement.length > 0 ? 'graph-area' : ''
        "
      >
        <!-- chart area -->
        <div id="" class="row">
          <div class="col-12 col-md-6">
            <IncomeStatementChart
              domId="chart"
              title=""
              distance="5"
              :titleMargin="10"
              :summary="mappedIncomeAndExpensePieChart"
            />
            <!-- <IncomeStatementChart
              domId="chart"
              title=""
              distance="5"
              :titleMargin="10"
              :summary="groupofIcomeAndExpense"
            /> -->
          </div>

          <div
            class="col-12 col-md-6"
            :class="{ 'show-report': showReport, 'hide-report': !showReport }"
          >
            <IncomeStatmentColumnChart
              domId="chart1"
              title=""
              distance="5"
              :titleMargin="10"
              :data="columnChart"
              subtitle=""
              :series="['Income', 'Expense']"
              yAxisText="Amount"
            />
          </div>
        </div>
        <!--end of chart area-->
      </section>

      <section class="container-fluid">
        <!-- table header -->
        <div class="row">
          <div
            class="col-md-12 px-0 d-flex justify-content-end"
            v-if="filteredAccount.length > 0 || searchText !== ''"
          >
            <div class="col-md-4 col-12 px-0 mt-5" v-if="!displayTitle">
              <el-input
                v-model="searchText"
                size="small"
                placeholder="Search..."
                :suffix-icon="Search"
              />
            </div>
          </div>
          <div
            id=""
            v-if="groupedIncomeItemToDisplay.length > 0"
            class="mt-2 container-fluid table-main remove-styles2 remove-border responsiveness"
          >
            <table
              id="table"
              class="table remove-styles mt-0 table-header-area"
            >
              <thead class="table-header-area-main">
                <tr
                  class="small-text text-capitalize text-nowrap font-weight-bold"
                  style="border-bottom: 0; font-size: medium"
                >
                  <th scope="col">Account Category</th>
                  <th scope="col">Account Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>

              <tbody
                class="font-weight-bold text-nowrap"
                style="font-size: small"
                v-for="(row, index) in  filteredAccount"
                :key="index"
              >
                <tr>
                  <td class="fundType-color" style="font-size: medium">
                    {{ row }}
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="(account, indx) in tableData[row].expenses"
                  :key="indx"
                >
                  <td class="accounType-color">
                    {{ indx === 0 ? account.accountCategory : "" }}
                  </td>
                  <td>{{ account.accountName }}</td>
                  <td>{{ account.description }}</td>
                  <td>
                    {{
                      account.currency && account.currency.symbol
                        ? account.currency.symbol
                        : ""
                    }}
                    {{ Math.abs(account.amount).toLocaleString() }}.00
                  </td>
                  <td>{{ formatDate(account.date) }}</td>
                </tr>
                <tr class="answer-row" v-if="tableData[row].incomes.length > 0">
                  <td class="subtotal">Subtotal</td>

                  <td></td>
                  <td></td>
                  <td class="subtotal">
                    {{ currentUser.currencySymbol }}
                    {{ sum(tableData[row].expenses).toLocaleString() }}.00
                  </td>
                  <td></td>
                </tr>
                <tr
                  v-for="(account, indx) in tableData[row].incomes"
                  :key="indx"
                >
                  <td class="accounType-color">
                    {{ indx === 0 ? account.accountCategory : "" }}
                  </td>
                  <td>{{ account.accountName }}</td>
                  <td>{{ account.description }}</td>
                  <td>
                    {{
                      account.currency && account.currency.symbol
                        ? account.currency.symbol
                        : ""
                    }}
                    {{ Math.abs(account.amount).toLocaleString() }}.00
                  </td>
                  <td>{{ formatDate(account.date) }}</td>
                </tr>
                <tr class="answer-row" v-if="tableData[row].incomes.length > 0">
                  <td class="subtotal">Subtotal</td>

                  <td></td>
                  <td></td>
                  <td class="subtotal">
                    {{ currentUser.currencySymbol }}
                    {{ sum(tableData[row].incomes).toLocaleString() }}.00
                  </td>
                  <td></td>
                </tr>
                <tr class="answer-row" v-if="tableData[row].incomes.length > 0">
                  <td class="total-answer">Total</td>

                  <td></td>
                  <td></td>
                  <td class="total-answer">
                    {{ currentUser.currencySymbol }}
                    {{
                      sumOfDiffAcctInFunds(tableData[row]).toLocaleString()
                    }}.00
                  </td>
                  <td></td>
                </tr>
                <tr style="background-color: #fff">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
              <tbody
                class="font-weight-bolder text-nowrap"
                style="font-size: small"
              >
                <tr class="answer-row2">
                  <td class="gross-total">Net Total</td>

                  <td></td>
                  <td></td>
                  <td class="gross-total responsive-horizontalrule">
                    <span
                      >{{ currentUser.currencySymbol }}
                      {{
                        Math.abs(diffBtwIncomeAndExpenses).toLocaleString()
                      }}.00</span
                    >
                    <hr class="horizontal-rule" />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--end table header -->
      </section>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, inject } from "vue";
import IncomeStatementChart from "@/components/charts/ReportPieChart.vue";
import IncomeStatmentColumnChart from "../../../components/charts/ReportColumnChart.vue";
import axios from "@/gateway/backendapi";
import dateFormatter from "../../../services/dates/dateformatter";
import printJS from "print-js";
import router from "../../../router";
// import exportService from "../../../services/exportFile/exportserviceforincomestatement.js";
// import exportService from "../../../services/exportFile/exportservice";
import exportService from "../../../services/exportFile/exportserjs";
import groupResponse from "../../../services/groupArray/groupResponse.js";
import incomeExpenseHelper from "./Helper/Incomeexpenses-helper.js";
import { useStore } from "vuex";
import { Search } from "@element-plus/icons-vue";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

export default {
  components: {
    IncomeStatementChart,
    IncomeStatmentColumnChart,
  },
  setup() {
    const store = useStore();
    const startDate = ref(new Date().setFullYear(new Date().getFullYear() - 1));
    const endDate = ref(new Date(Date.now()));
    const incomeStatement = ref([]);
    const groupedIncomeStatements = ref([]);
    const groupedExpenseStatements = ref([]);
    const groupedExpenseItemToDisplay = ref([]);
    const chartForIcomeAndExpense = ref([]);
    const groupofIcomeAndExpense = ref([]);
    // const mappedIncomeAndExpense = ref([])
    const allIncomeAndExpenses = ref([]);
    const incomeStatementData = ref([]);
    const primarycolor = inject("primarycolor");
    const loading = ref(false);
    const showExport = ref(false);
    const showReport = ref(false);
    const displayTitle = ref(false);
    const fileName = ref("Income Statement Report");
    const bookTypeList = ref([
      { name: "xlsx" },
      { name: "csv" },
      { name: "txt" },
      { name: "pdf" },
    ]);
    const selectedFileType = ref("");
    const searchText = ref("");
    const fileHeaderToExport = ref([]);
    const fileToExport = ref([]);
    const fundType = ref([]);
    const funds = ref([]);
    // const startD = ref("2025-03-02");
    // const endD = ref("2025-03-20");

    const currentUser = computed(() => {
      if (store && Object.keys(store.getters.currentUser).length > 0)
        return store.getters.currentUser;
      return "";
    });
    const filteredAccount = computed(() => {
      if (!searchText.value.trim()) {
        return tableRows.value;
      }
      return tableRows.value.filter((item) =>
        Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(searchText.value.toLowerCase())
      );
    });

    const totalIncome = computed(() =>
      accountItem.value.reduce((sum, account) => sum + account.amount, 0)
    );
    const income = ref(300000.2);
    const expenses = ref(200000.2);
    const netProfit = computed(() => income.value - expenses.value);

    // Format Amount

    const selectedTab = ref("details");
    const accountItem = ref([
      { name: "Sam Loko", amount: 20000 },
      { name: "Sam Loko", amount: 20000 },
      { name: "Sam Loko", amount: 20000 },
      { name: "Sam Loko", amount: 20000 },
      { name: "Sam Loko", amount: 20000 },
    ]);
    // const formatDate = (dateStr) => new Date(dateStr).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" });

    const tableData = ref({});
    const tableRows = computed(() => {
      if (!tableData.value) return [];
      return Object.keys(tableData.value);
    });

    const sum = (arr) => {
      if (!arr || arr.length <= 0) return 0;
      const amounts = arr.map((account) => account.amount);
      return Math.abs(amounts.reduce((a, b) => a + b));
    };

    const getSumOfIncomes = (type) => {
      let total = 0;
      for (let fund in tableData.value) {
        if (fund === "null") break;
        const amount = sum(tableData.value[fund][type]);
        total += amount;
      }
      return total;
    };

    const diffBtwIncomeAndExpenses = computed(() => {
      if (!tableData.value) return 0;
      const incomeTotal = getSumOfIncomes("incomes");
      const expenseTotal = getSumOfIncomes("expenses");

      return incomeTotal - expenseTotal;
    });
    const totalExpenses = computed(() => {
      if (!tableData.value) return 0;
      const expenseTotal = getSumOfIncomes("expenses");

      return expenseTotal;
    });
    const totalIncomes = computed(() => {
      if (!tableData.value) return 0;
      const incomeTotal = getSumOfIncomes("incomes");

      return incomeTotal;
    });

    const goBack = () => {
      router.go(-1);
    };

    const sumOfDiffAcctInFunds = (item) => {
      let incomeres = item.incomes.reduce((a, b) => {
        return { amount: +a.amount + +b.amount };
      });

      let expenseres =
        item.expenses.length > 0
          ? item.expenses.reduce((a, b) => {
              return { amount: +a.amount + +b.amount };
            })
          : 0;

      const subtractValue =
        Math.abs(incomeres.amount || 0) - Math.abs(expenseres.amount || 0);
      return subtractValue;
    };

    const generateReport = () => {
      loading.value = true;
      axios
        .get(
          `/api/Reports/financials/getIncomeStatementReport?startDate=${new Date(
            startDate.value
          ).toLocaleDateString("en-US")}&endDate=${new Date(
            endDate.value
          ).toLocaleDateString("en-US")}`
        )
        .then((res) => {
          tableData.value = incomeExpenseHelper.formatAccounts(res.data);
          incomeStatement.value = res.data.filter((i) => i !== null);

          if (incomeStatement.value.length > 0) {
            showReport.value = true;
          } else {
            showReport.value = false;
          }

          let response = res.data;
          chartForIcomeAndExpense.value = response;
          churchIncomes(incomeStatement.value, "accountCategory");
          churchExpense(incomeStatement.value, "accountCategory");
          pieChart(incomeStatement.value, "accountCategory");
          groupedFundType();
          groupAccountCategoery();

          /* function to call service and populate table */
          setTimeout(() => {
            fileHeaderToExport.value = exportService.tableHeaderToJson(
              document.getElementsByTagName("th")
            );
            fileToExport.value = exportService.tableToJson(
              document.getElementById("table")
            );
          }, 1000);
          /* End function to call service and populate table */
          loading.value = false;
        })
        .catch((err) => {
          console.log(err);
          loading.value = false;
        });
    };

    const groupedFundType = () => {
      fundType.value = groupResponse.groupData(incomeStatement.value, "fund");
      for (const prop in fundType.value) {
        funds.value.push({ name: prop, value: fundType.value[prop] });
      }
    };
    groupedFundType();

    const groupAccountCategoery = (arr) => {
      if (!arr || arr.length === 0) return;
      const category = [];
      const categoryType = groupResponse.groupData(arr, "accountCategory");
      for (const prop in categoryType) {
        category.push({
          name: prop,
          value: categoryType[prop],
        });
      }
      return category;
    };
    groupAccountCategoery();

    /* Code For Exporting File */
    const downloadFile = async (item) => {
      if (item.name === "pdf") {
        displayTitle.value = true;
        await nextTick();
        exportService.downLoadExcel(
          item.name,
          document.getElementById("element-to-print"),
          fileName.value,
          fileHeaderToExport.value,
          fileToExport.value
        );
      } else {
        displayTitle.value = false;
        //   exportService.downLoadExcel(
        //   item.name,
        //   document.getElementById("element-to-print"),
        //   fileName.value,
        //   fileHeaderToExport.value,
        //   fileToExport.value
        // );
        exportService.downLoadExcel(
          item.name,
          null,
          fileName.value,
          fileHeaderToExport.value,
          fileToExport.value
        );
      }
    };
    /* End Code For Exporting File */

    /* Chart Area */
    const pieChart = (array, key) => {
      let result = array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
      groupofIcomeAndExpense.value = [];
      for (const prop in result) {
        groupofIcomeAndExpense.value.push({
          name: prop,
          value: result[prop].length,
          // value: result[prop].reduce((acc, cur) => {
          //   return Math.abs(acc + cur.amount);
          // }, 0),
        });
      }
    };

    const mappedIncomeAndExpensePieChart = computed(() => {
      if (groupofIcomeAndExpense.value.length === 0) return [];
      return groupofIcomeAndExpense.value.map((i) => i);
    });

    const columnChart = computed(() => {
      let expenseValue = groupofIcomeAndExpense.value.find(
        (i) => i.name === "Expense"
      );
      let incomeValue = groupofIcomeAndExpense.value.find(
        (i) => i.name === "Income"
      );
      allIncomeAndExpenses.value = [];
      if (groupofIcomeAndExpense.value.length === 0) return [];
      allIncomeAndExpenses.value.push({
        name: expenseValue ? expenseValue.name : "",
        //  color:  expenseValue.name == 'Expense' ? '#0066FF' : '',
        data: expenseValue ? [expenseValue.value] : [],
      });

      allIncomeAndExpenses.value.push({
        name: incomeValue ? incomeValue.name : "",
        //  color: incomeValue.name ? '#CCCCCC' : '',
        data: incomeValue ? [incomeValue.value] : [],
      });
      return allIncomeAndExpenses.value;
    });
    /*End of Chart Area */

    let groupedIncomeItemToDisplay = ref([]);
    const churchIncomes = (array, key) => {
      let result = array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate

        return result;
      }, {}); // empty object is the initial value for result object
      groupedIncomeStatements.value = [];
      for (const prop in result) {
        groupedIncomeStatements.value.push({
          name: prop,
          value: result[prop],
        });
      }
      groupedIncomeItemToDisplay.value = groupedIncomeStatements.value[0].value;
    };

    const churchExpense = (array, key) => {
      let result = array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
      groupedExpenseStatements.value = [];
      for (const prop in result) {
        groupedExpenseStatements.value.push({
          name: prop,
          value: result[prop],
        });
      }
      groupedExpenseItemToDisplay.value =
        groupedExpenseStatements.value[1].value;
    };

    const fundSum = computed(() => {
      if (incomeStatement.value.length === 0) return 0;
      return incomeStatement.value.reduce((a, b) => {
        return a + b.amount;
      }, 0);
    });

    const formatDate = (activityDate) => {
      return dateFormatter.monthDayYear(activityDate);
    };

    return {
      totalIncome,
      filteredAccount,
      searchText,
      Search,
      selectedTab,
      netProfit,
      totalExpenses,
      totalIncomes,
      income,
      expenses,
      sumOfDiffAcctInFunds,
      groupAccountCategoery,
      mappedIncomeAndExpensePieChart,
      primarycolor,
      loading,
      fundSum,
      startDate,
      endDate,
      goBack,
      incomeStatement,
      generateReport,
      formatDate,
      groupedIncomeStatements,
      groupedExpenseStatements,
      displayTitle,
      churchIncomes,
      churchExpense,
      groupedIncomeItemToDisplay,
      groupedExpenseItemToDisplay,
      chartForIcomeAndExpense,
      groupofIcomeAndExpense,
      pieChart,
      incomeStatementData,
      printJS,
      showExport,
      showReport,
      fileName,
      bookTypeList,
      selectedFileType,
      downloadFile,
      fundType,
      funds,
      groupedFundType,
      tableRows,
      tableData,
      sum,
      diffBtwIncomeAndExpenses,
      columnChart,
      currentUser,
    };
  },
};
</script>

<style scoped>
.active {
  background: #ffffff;
  color: #08162e;
  font-weight: 700;
  width: 81px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  /* padding: 15px 0; */
}
button.el-button {
  height: 36px !important;
  width: 139px !important;
  padding: 13px 24px !important;
  font-size: 13px !important;
}

.generate-report {
  font-size: 1rem;
  color: #fff;
  background-color: #136acd !important;
  border: none;
  min-width: 7rem;
}

.bg-area {
  background-color: #ebeff4;
  border-radius: 0.5rem;
  padding: 0.2rem 0 1.2rem 0;
}

.table {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  text-align: left;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem;
}

.table thead th {
  font-weight: 400 !important;
  color: #000000 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.table tbody tr {
  font-weight: 400 !important;
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.table-header-area-main {
  background-color: #d4dde4;
  /* background-color: #f4f4f4; */
}

.table-header-area {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.show-report {
  display: block;
}
.hide-report {
  display: none;
}

.table-main {
  width: 100% !important;
  box-shadow: 0 0.063rem 0.25rem #02172e45 !important;
  border: 0.063rem solid #dde2e6 !important;
  /* border-radius: 30px !important; */
  text-align: left !important;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem !important;
}

.remove-styles {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remove-styles2 {
  padding-right: 0;
  padding-left: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.remove-border {
  box-shadow: none !important;
}

.tablerow-style {
  min-width: 100%;
  border-bottom: 0px;
}

.graph-area {
  border: 1px solid #dde2e6;
  border-radius: 0.5rem;
  padding: 1rem 0rem;
  margin: 2rem 0rem;
}

.responsiveness {
  max-width: 100%;
  overflow-x: scroll;
  /* overflow-y: scroll; */
}

.subtotal {
  font-weight: 700;
  font-size: 14px;
  /* color: #136acd; */
  color: #000000;
}

.total-answer {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  /* color: #136acd; */
}

.answer-row {
  background-color: #d4dde4;
  /* background-color: #ebeff4; */
}

.answer-row:hover {
  background-color: none;
}

.fundType-color {
  color: #136acd;
  font-size: larger;
}

.accounType-color {
  font-size: medium;
}

.horizontal-rule {
  border-radius: 5px;
  margin: 0.125rem 0;
  background: #000000;
  /* background: white; */
  height: 1px;
}

.responsive-horizontalrule {
  display: inline-block;
}

.answer-row2 {
  background-color: #d4dde4;
  /* background-color: #136acd; */
}

.gross-total {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  /* color: #fff; */
}

/* .move-enter-active {
  animation: move-in .8s;
}
.move-leave-active {
  animation: move-in .8s reverse;
}
@keyframes move-in {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }

} */

/* .fade-enter-active {
  animation: fade-in .5s;
}
.fade-leave-active {
  animation: fade-in .5s reverse;
}
@keyframes fade-in {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }

} */
@media print {
  .container-fluid {
    width: 100%; /* Avoid fixed widths */
  }
  table {
    border-collapse: collapse; /* Prevent gaps in table borders */
  }
  th,
  td {
    word-wrap: break-word; /* Handle long text in cells */
  }
  .d-flex {
    display: block !important; /* Ensure flex layouts render properly */
  }
  .hide-on-print {
    display: none !important; /* Exclude unnecessary elements */
  }
}
</style>

<template>
  <div class="container-fluid">
    <!-- Title -->
    <div class="row flex-row justify-content-between">
      <div class="mb-4">
        <div class="text-head font-weight-bold h2 py-0 my-0 text-black">
          Income Statement
        </div>
      </div>
      <div class="c-pointer">
        <el-dropdown trigger="click" class="w-100">
          <div class="d-flex default-btn text-dark w-100" size="large">
            <span class="mt-1 s-14 text-head" style="color: #0040ff"
              >Export</span
            >
            <div class="mt-0 ml-1">
              <el-icon :size="10" class="el-icon--right primary--text"
                ><ArrowDownBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(bookType, index) in bookTypeList"
                :key="index"
              >
                <a
                  class="no-decoration text-dark"
                  @click="downloadFile(bookType)"
                >
                  {{ bookType.name }}
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="row bg-gray-300 justify-content-center py-3 border-radius-8">
      <div class="col-md-12 pl-4">
        <div class="row align-items-center">
          <div class="col-md-8 col-12">
            <div class="row align-items-center">
              <div class="col-md-3 col-12">
                <div class="text-md-right text-left s-14 fw-400 text-dak">
                  Date Range
                </div>
              </div>
              <div class="col-md-3 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-md-1">
                  <el-date-picker
                    v-model="startDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
              <div class="col-md-3 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-md-1">
                  <el-date-picker
                    v-model="endDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div
              class="col-md-12 mt-3 d-flex justify-content-center justify-content-md-end px-0"
            >
              <el-button
                class="c-pointer w-100"
                :color="primarycolor"
                :loading="loading"
                round
                @click="generateReport"
              >
                Generate Report
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="text-center">
          <div class="row justify-content-center">
            <div
              class="col-md-10 d-flex justify-content-between align-items-end"
            >
              <div class="fw-400 s-14 text-dak">
                <span>Income</span>
                <div class="s-24 font-weight-600 text-head text-dak">
                  {{ currentUser.currencySymbol }}
                  {{ Math.abs(totalIncome).toLocaleString() }}.00
                </div>
              </div>
              <span>-</span>
              <div class="fw-400 s-14 text-dak">
                <span>Expenses</span>
                <div class="s-24 font-weight-600 text-head text-dak">
                  {{ currentUser.currencySymbol }}
                  {{ Math.abs(totalExpense).toLocaleString() }}.00
                </div>
              </div>
              <span>=</span>
              <div class="fw-400 s-14 text-dak">
                <span>Net Profit</span>
                <div
                  style="color: #088c35"
                  class="s-24 text-head font-weight-600"
                >
                  {{ currentUser.currencySymbol }}
                  {{ Math.abs(diffBtwIncomeAndExpenses).toLocaleString() }}.00
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tabs -->
        <div class="d-flex justify-content-center mt-5 cursor-pointer">
          <div
            style="background: #e0f2ff"
            class="p-1 d-flex align-items-center border-radius-8"
          >
            <div
              style="color: #08162e"
              class="s-12 px-3"
              :class="{ active: selectedTab === 'summary' }"
              @click="selectedTab = 'summary'"
            >
              Summary
            </div>
            <div
              style="color: #08162e"
              class="s-12 px-3"
              :class="{ active: selectedTab === 'details' }"
              @click="selectedTab = 'details'"
            >
              Details
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 px-0" v-if="selectedTab === 'summary'">
        <IncomeStatementSummary />
      </div>
    </div>
    <!-- Accounts Table -->
    <div class="mt-3 row" v-if="selectedTab === 'details'">
      <div
        class="container-fluild table-main remove-styles2 remove-border responsiveness"
      >
        <table
          id="table"
          class="table remove-styles mt-0 table-hover table-header-area"
        >
          <thead class="table-header-ar">
            <tr class="font-weight-bold">
              <th>ACCOUNTS</th>
              <th class="text-right"></th>
              <th class="text-right">
                <div class="text-dak s-14">Mar 02, 2025</div>
                <div class="text-dak s-14">to Mar 20, 2025</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Assets Section -->
            <tr class="table-header-area-main fw-400 s-14">
              <th class="fw-400 text-dak s-14" colspan="3">Income</th>
            </tr>
            <tr v-for="(item, index) in income" :key="index">
              <td>
                <a
                  href="#"
                  class="text-decoration-none fw-500 s-12"
                  style="color: #0b55d4"
                  >{{ item.name }}</a
                >
              </td>
              <td></td>
              <td class="text-right">
                {{ currentUser.currencySymbol }} {{ formatAmount(item.amount) }}
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td class="text-dak font-weight-bold s-14">Total Income</td>
              <td class="text-right text-dak font-weight-bold s-14"></td>
              <td class="text-right text-dak font-weight-bold s-14">
                {{ currentUser.currencySymbol }}
                {{ formatAmount(totalIncome) }}
              </td>
            </tr>
          </tbody>
          <div class="mt-4"></div>
          <tbody>
            <!-- Assets Section -->
            <tr class="table-header-area-main fw-400 s-14">
              <th class="fw-400 text-dak s-14" colspan="3">Opening Expenses</th>
            </tr>
            <tr v-for="(item, index) in expense" :key="index">
              <td>
                <a
                  href="#"
                  class="text-decoration-none fw-500 s-12"
                  style="color: #0b55d4"
                  >{{ item.name }}</a
                >
              </td>
              <td class="text-right">
                <!-- {{ formatAmount(item.amount) }} -->
              </td>
              <td class="text-right">
                {{ currentUser.currencySymbol }}
                {{ formatAmount(item.amount) }}
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td class="text-dak font-weight-bold s-14">
                Total Opening Expense
              </td>
              <td class="text-right text-dak font-weight-bold s-14"></td>
              <td class="text-right text-dak font-weight-bold s-14">
                {{ currentUser.currencySymbol }}
                {{ formatAmount(totalExpense) }}
              </td>
            </tr>
          </tbody>
          <!-- <tbody>
              <tr
                class="font-weight-bold"
                style="border-bottom: 1px solid rgba(0, 0, 1, 0.2) !important"
              >
                <td class="text-dak font-weight-bold s-14">
                  Total for all account
                </td>
                <td
                  class="text-right text-dak font-weight-bold s-14"
                  style="
                    border-bottom: 1px solid #000000 !important;
                    border-top: 1px solid #000000 !important;
                  "
                >
                  {{ formatAmount(totalDebitAssets + totalDebitLiabilities) }}
                </td>
                <td
                  class="text-right text-dak font-weight-bold s-14"
                  style="
                    border-bottom: 1px solid #000000 !important;
                    border-top: 1px solid #000000 !important;
                  "
                >
                  {{ formatAmount(totalCreditAssets + totalCreditLiabilities) }}
                </td>
              </tr>
            </tbody> -->
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import exportService from "../../../services/exportFile/exportservice";
import { useStore } from "vuex";
import IncomeStatementSummary from "./IncomeStatementSummary.vue";

// Date & Filters
// const selectedDate = ref(new Date(Date.now()));
const startDate = ref(new Date().setFullYear(new Date().getFullYear() - 1));
const endDate = ref(new Date(Date.now()));
const loading = ref(false);
const primarycolor = inject("primarycolor");
const displayTitle = ref(false);
const fileName = ref("First Timers(M) Report");
const bookTypeList = ref([
  { name: "xlsx" },
  { name: "csv" },
  { name: "txt" },
  { name: "" },
]);
// const selectedFileType = ref({});
const fileHeaderToExport = ref([]);
const fileToExport = ref([]);
const store = useStore();
const selectedTab = ref("details");

const downloadFile = async (item) => {
  if (item.name === "pdf") {
    displayTitle.value = true;
    await nextTick();
    exportService.downLoadExcel(
      item.name,
      document.getElementById("element-to-print"),
      fileName.value,
      fileHeaderToExport.value,
      fileToExport.value
    );
  } else {
    displayTitle.value = false;
    exportService.downLoadExcel(
      item.name,
      document.getElementById("element-to-print"),
      fileName.value,
      fileHeaderToExport.value,
      fileToExport.value
    );
  }
};

// Sample Data
const expense = ref([
  { name: "Courtney Henry", amount: 15000 },
  { name: "Annette Black", amount: 15000 },
  { name: "Marvin McKinney", amount: 15000 },
  { name: "Floyd Miles", amount: 15000 },
  { name: "Jerome Bell", amount: 15000 },
  { name: "Darlene Robertson", amount: 15000 },
  { name: "Cody Fisher", amount: 15000 },
  { name: "Dianne Russell", amount: 15000 },
  { name: "Arlene McCoy", amount: 15000 },
]);

const income = ref([
  { name: "Sam Loko", amount: 20000 },
  { name: "Sam Loko", amount: 20000 },
  { name: "Sam Loko", amount: 20000 },
  { name: "Sam Loko", amount: 20000 },
  { name: "Sam Loko", amount: 20000 },
]);

const currentUser = computed(() => {
  if (store && Object.keys(store.getters.currentUser).length > 0)
    return store.getters.currentUser;
  return "";
});

// Computed Totals
const totalExpense = computed(() =>
  expense.value.reduce((sum, item) => sum + item.amount, 0)
);
const totalIncome = computed(() =>
  income.value.reduce((sum, item) => sum + item.amount, 0)
);
const diffBtwIncomeAndExpenses = computed(() => {
  return totalIncome.value - totalExpense.value;
});

// Format Amount
const formatAmount = (amount) =>
  amount.toLocaleString("en-NG", { minimumFractionDigits: 2 });
</script>

<style scoped>
.table th,
.table td {
  border-top: none;
}
.active {
  background: #ffffff;
  color: #08162e;
  font-weight: 700;
  width: 81px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  /* padding: 15px 0; */
}
.bg-secondary {
  background-color: #f8f9fa !important;
}
button.el-button {
  height: 36px !important;
  width: 139px !important;
  padding: 13px 24px !important;
  font-size: 13px !important;
}
.table-main {
  width: 100% !important;
  box-shadow: 0 0.063rem 0.25rem #02172e45 !important;
  /* border: 0.063rem solid #dde2e6 !important; */
  /* border-radius: 30px !important; */
  text-align: left !important;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem !important;
}

.remove-styles {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remove-styles2 {
  padding-right: 0;
  padding-left: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
table tbody tr,
table thead tr {
  border-bottom: 1px solid rgba(0, 0, 1, 0.2);
}
table tbody tr:first-child {
  border-bottom: none !important;
}
table tbody tr:last-child {
  border-bottom: none !important;
}

.remove-border {
  box-shadow: none !important;
}
.table {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  text-align: left;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem;
}

.table thead th {
  font-weight: 700 !important;
  color: #000000 !important;
  font-size: 14px !important;
}
.table tbody tr {
  font-weight: 400 !important;
  color: #000000 !important;
  font-size: 14px !important;
}

.table-header-area-main {
  background-color: #d4dde4;
  /* background-color: #f4f4f4; */
}

.table-header-area {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

<template>
  <div v-if="placeHolderText">
    <el-select size="small" v-model="selectedCategoryID" multiple collapse-tags :placeholder="placeHolderText" class="w-100">
      <el-option @click="selectAllCategory" label="Select All" value="Select All" />
      <el-option @click="setSelectedCategoryItem" v-for="item in allItems" :key="item.id"
        :label="item.name || item.text" :value="item.id" />
    </el-select>
  </div>
  <div v-else>
    <el-select size="small" v-model="selectedCategoryID" multiple collapse-tags placeholder="Select" class="w-100">
      <el-option @click="selectAllCategory" label="Select All" value="Select All" />
      <el-option @click="setSelectedCategoryItem" v-for="item in allItems" :key="item.id"
        :label="item.name || item.text" :value="item.id" />
    </el-select>
  </div>
</template>

<script>

import { computed, ref, nextTick, inject, watchEffect } from "vue";
export default {
  props: ["items", 'placeHolderText'],
  emits: ["selected-item"],

  setup(props, { emit }) {

    const selectedCategory = ref([])
    const selectedCategoryID = ref([])
    const selectedPleCategory = ref(false)

    const allItems = ref([])

    watchEffect(() => {
      if (props.items) {
        allItems.value = props.items
      }
    })

    const selectAllCategory = () => {
      selectedPleCategory.value = !selectedPleCategory.value
      selectedCategory.value = []
      if (selectedPleCategory.value === true) {
        selectedCategoryID.value = allItems.value.map((i) => i.id)
      } else {
        selectedCategoryID.value = []
      }
      if (selectedCategoryID.value.length > 0) {
        selectedCategory.value = allItems.value
        emit("selected-item", selectedCategory.value);
      }

      // } 
    }

    const setSelectedCategoryItem = () => {
      selectedCategory.value = []
      selectedCategoryID.value.forEach((item) => {
        selectedCategory.value.push(allItems.value.find((i) => i.id == item))
      })
      emit("selected-item", selectedCategory.value);

    }

    return {
      selectAllCategory,
      setSelectedCategoryItem,
      selectedPleCategory,
      selectedCategory,
      selectedCategoryID,
      allItems
    }
  },
}
</script>

<style scoped></style>

<template>
  <div class="container-top container-slim d-flex">
    <div class="text-center mt-2" v-if="routerPage">
      <div class="border rounded p-1 cursor-pointer" @click="goBack">
        <el-icon :size="20" class="d-flex"><ArrowLeft /></el-icon>
      </div>
    </div>
    <div :class="{ 'container-slim': lgAndUp || xlAndUp }">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { watch, ref } from "vue";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const routerPage = ref('');
const { lgAndUp, xlAndUp } = deviceBreakpoint();
const goBack = () => {
  router.go(-1);
};

const checkRoute = () => {
  routerPage.value = route.fullPath.includes('/tenant/reports/');
};

// Watch for route changes
watch(() => route.fullPath, () => {
  checkRoute();
}, { immediate: true });
</script>

<style scoped></style>

<template>
  <div class="container-fluid" @click="closeDropdownIfOpen">
    <div class="row flex-row justify-content-between">
      <div class="mb-4">
        <div class="text-head font-weight-bold h2 py-0 my-0 text-black">
          Event Checkin & Attendance Report
        </div>
        <!-- <div @click="goBack">
          <span class="s-18 fw-400 cursor-pointer text-black">
            <img src="../../../assets/goback.png" alt="" /> Go back</span
          >
        </div> -->
      </div>
      <div class="c-pointer">
        <el-dropdown trigger="click" class="w-100">
          <div class="d-flex default-btn text-dark w-100" size="large">
            <span class="mt-1 s-14 text-head" style="color: #0040ff"
              >Export</span
            >
            <div class="mt-0 ml-1">
              <el-icon :size="10" class="el-icon--right primary--text"
                ><ArrowDownBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(bookType, index) in bookTypeList"
                :key="index"
              >
                <a
                  class="no-decoration text-dark"
                  @click="downloadFile(bookType)"
                >
                  {{ bookType.name }}
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="row bg-gray-300 justify-content-center py-3 border-radius-8">
      <div class="col-md-11 mr-4 ml-2">
        <div class="row">
          <div class="col-md-6 px-0 d-flex flex-wrap align-items-center">
            <div class="col-md-4 s-14 text-dak fw-400 col-12">Date range</div>
            <div class="col-md-4 col-12 px-0">
              <div class="col-md-12 px-md-0 pb-2 d-flex">
                <el-date-picker
                  v-model="startDate"
                  type="date"
                  format="DD/MM/YYYY"
                  class="w-100 calendar"
                />
              </div>
            </div>

            <div class="col-md-4 col-12 px-0 mb-3">
              <div class="col-md-12 px-md-0 pt-md-2">
                <!-- <div>
                <label for="icon" class="fw-400 mb-0 text-dak s-14"
                  >End Date</label
                >
              </div> -->
                <el-date-picker
                  v-model="endDate"
                  type="date"
                  format="DD/MM/YYYY"
                  class="w-100 calendar"
                />
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-12 pr-md-0">
            <div class="p-field pt-md-2 p-col-12">
              <!-- <div>
                <label for="icon" class="fw-400 mb-0 text-dak s-14"
                  >Select Categories</label
                >
              </div> -->
              <el-select-v2
                v-model="selectedEventID"
                class="w-100 font-weight-normal report-select"
                :options="
                  events.map((i) => ({
                    label: i.text,
                    value: i.id,
                  }))
                "
                placeholder="Select event"
                @change="setSelectedEvent"
                size="large"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-3 pr-md-0 mt-2 pt-md-2 mt-md-0">
            <div class="">
              <button
                class="form-control d-flex justify-content-between align-items-center exempt-hide"
                @click="setGroupProp"
              >
                <span class="exempt-hide">
                  <span
                    v-if="checkedGroup.length > 0 && checkedGroup.length <= 2"
                  >
                    <span v-for="item in checkedGroup" :key="item.id"
                      ><span class="eachGroup">{{ item.name }}</span></span
                    >
                  </span>
                  <span
                    v-if="checkedGroup.length > 0 && checkedGroup.length > 2"
                  >
                    <span
                      v-for="item in checkedGroup.slice(0, 2)"
                      :key="item.id"
                      ><span class="eachGroup">{{ item.name }}</span></span
                    >
                    ...
                  </span>
                  <span v-if="checkedGroup.length === 0">Select group</span>
                </span>
                <el-icon class="exemple-hide"><ArrowDown /></el-icon>
              </button>
              <div
                class="div-card p-2 exempt-hide"
                :class="{
                  'd-none': hideDiv,
                  'd-block': !hideDiv,
                }"
              >
                <el-icon
                  v-if="grouploading && groups.length === 0"
                  class="is-loading text-center exempt-hide"
                >
                  <Loading />
                </el-icon>
                <input
                  type="text"
                  class="form-control exempt-hide"
                  v-model="searchGroupText"
                  ref="searchGroupRef"
                  placeholder="Search for group"
                />
                <GroupTree
                  :items="searchForGroups"
                  :addGroupValue="true"
                  @filteredGroup="setFilterGroups"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-11">
        <div class="row">
          <div
            class="col-md-12 d-flex justify-content-end mt-4"
            @click="getAttendanceReport"
          >
            <el-button
              round
              :color="primarycolor"
              :loading="loading"
              size="large"
              class="text-white w-100 report-button c-pointer"
              >Generate Report
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mt-4"
    id="element-to-print"
    v-if="groupedReport.length > 0 && searched"
  >
    <div
      class="container-fluid d-flex justify-content-center my-2"
      v-if="displayTitle"
    >
      <div class="text-head font-weight-bold h2 py-0 my-0 text-black">
        Attendance Report
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <GroupReportTable
          :groupedReport="groupedReport"
          :groupedReportByDate="groupedReportByDate"
          @data-to-export="setDataToExport"
          @data-header-to-export="setTableHeaderData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, inject } from "vue";
import GroupReportTable from "./CheckinAttendanceReportTable.vue";
import axios from "@/gateway/backendapi";
import { ElMessage } from "element-plus";
import exportService from "../../../services/exportFile/exportservice";
import router from "../../../router";
import GroupTree from "../../groups/component/GroupTreeCheckboxParent.vue";
import grousService from "../../../services/groups/groupsservice";
export default {
  components: {
    GroupReportTable,
    GroupTree,
  },
  setup() {
    const startDate = ref(new Date().setFullYear(new Date().getFullYear() - 1));
    const endDate = ref(new Date(Date.now()));
    const events = ref([]);
    const primarycolor = inject("primarycolor");
    const groups = ref([]);
    const displayTitle = ref(false);
    const selectedEvent = ref({});
    const selectedEventID = ref(null);
    const selectedGroups = ref({});
    const attendanceReport = ref([]);
    const groupedReport = ref([]);
    const groupedReportByDate = ref([]);
    const bookTypeList = ref([
      { name: "xlsx" },
      { name: "csv" },
      { name: "txt" },
      { name: "pdf" },
    ]);
    const selectedFileType = ref({});
    const fileName = ref("Attendance Report");
    const showExport = ref(false);
    const fileToExport = ref([]);
    const fileHeaderToExport = ref([]);
    const searched = ref(false);
    const loading = ref(false);
    const searchGroupRef = ref();
    const searchGroupText = ref("");
    const hideDiv = ref(true);
    const grouploading = ref(false);
    const checkedGroup = ref([]);

    const goBack = () => {
      router.go(-1);
    };

    const getEvents = async () => {
      try {
        let { data } = await axios.get(`/api/Reports/events/getEvents`);
        events.value = data;
      } catch (err) {
        console.log(err);
      }
    };
    getEvents();
    const setSelectedEvent = () => {
      selectedEvent.value = events.value.find(
        (i) => i.id === selectedEventID.value
      );
    };

    const getGroups = async () => {
      grouploading.value = true;
      try {
        let data = await grousService.getGroups();
        groups.value = data.response.groupResonseDTO;
        grouploading.value = false;
      } catch (err) {
        console.log(err);
        grouploading.value = false;
      }
    };
    getGroups();

    const getAttendanceReport = async () => {
      let start = new Date(startDate.value).toLocaleDateString("en-US");
      let end = new Date(endDate.value).toLocaleDateString("en-US");
      loading.value = true;

      const payload = {
        groupIDs: checkedGroup.value,
        eventID: selectedEvent.value.id,
        startDate: start,
        endDate: end,
      };

      try {
        let { data } = await axios.post(
          `/api/Reports/events/getCheckInAttendanceReport`,
          payload
        );
        searched.value = true;
        loading.value = false;
        attendanceReport.value = data;
        groupReport(data, "personId");
        groupReportByDate(data, "activityID");

        if (data.length === 0 && searched.value) {
          ElMessage({
            type: "warning",
            showClose: true,
            message: "No data for this date range",
            duration: 5000,
          });
        }
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    };

    const groupReport = (array, key) => {
      let result = array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
      groupedReport.value = [];
      for (const prop in result) {
        groupedReport.value.push({
          name: prop,
          value: result[prop],
        });
      }
    };

    const groupReportByDate = (array, key) => {
      let result = array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
      groupedReportByDate.value = [];
      for (const prop in result) {
        groupedReportByDate.value.push({
          name: prop,
          value: result[prop],
        });
      }
    };

    // const getIPDetails = async() => {
    //     try {
    //         let data = await axio.get('http://www.geoplugin.net/json.gp?ip=52.25.109.230')
    //         console.log(data)
    //     }
    //     catch (err) {
    //         console.log(err)
    //     }
    // }

    // getIPDetails()

    // const downLoadExcel = (item) => {
    //   if (item.name === "pdf") {
    //     displayTitle.value = true;
    //     var element = document.getElementById("element-to-print");
    //     var opt = {
    //       // margin:       1,
    //       filename: `${fileName.value}.pdf`,
    //       image: { type: "jpeg", quality: 0.98 },
    //       html2canvas: { scale: 2 },
    //       jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    //       pagebreak: { mode: ["avoid-all"] },
    //     };
    //     html2pdf().set(opt).from(element).save();
    //   } else {
    //     displayTitle.value = false;
    //     const filterVal = fileHeaderToExport.value.map((i, index) => index);
    //     const list = fileToExport.value;
    //     const header = fileHeaderToExport.value;
    //     ExcelExport.exportToExcel(filterVal, list, header, fileName.value, item.name);
    //   }
    // };
    const downloadFile = async (item) => {
      if (item.name === "pdf") {
        displayTitle.value = true;
        await nextTick();
        exportService.downLoadExcel(
          item.name,
          document.getElementById("element-to-print"),
          fileName.value,
          fileHeaderToExport.value,
          fileToExport.value
        );
      } else {
        displayTitle.value = false;
        exportService.downLoadExcel(
          item.name,
          document.getElementById("element-to-print"),
          fileName.value,
          fileHeaderToExport.value,
          fileToExport.value
        );
      }
    };

    const setDataToExport = (payload) => {
      fileToExport.value = payload;
    };

    const setTableHeaderData = (payload) => {
      fileHeaderToExport.value = payload;
    };

    const setGroupProp = () => {
      hideDiv.value = !hideDiv.value;
      nextTick(() => {
        searchGroupRef.value.focus();
      });
    };

    const searchForGroups = computed(() => {
      if (!searchGroupText.value && groups.value.length > 0)
        return groups.value;
      return groups.value.filter((i) =>
        i.name.toLowerCase().includes(searchGroupText.value.toLowerCase())
      );
    });
    const closeDropdownIfOpen = (e) => {
      if (
        !e.target.classList.contains("exempt-hide") &&
        !e.target.classList.contains("p-hidden-accessible") &&
        !e.target.classList.contains("p-checkbox-box") &&
        !e.target.classList.contains("p-checkbox-icon")
      ) {
        hideDiv.value = true;
      }
    };

    const setFilterGroups = (payload) => {
      checkedGroup.value = payload;
    };

    return {
      startDate,
      displayTitle,
      endDate,
      events,
      groups,
      setSelectedEvent,
      selectedEvent,
      getAttendanceReport,
      selectedEventID,
      selectedGroups,
      attendanceReport,
      groupReport,
      groupedReport,
      groupReportByDate,
      groupedReportByDate,
      downloadFile,
      selectedFileType,
      bookTypeList,
      fileName,
      showExport,
      setDataToExport,
      fileToExport,
      setTableHeaderData,
      fileHeaderToExport,
      primarycolor,
      searched,
      loading,
      setGroupProp,
      searchGroupRef,
      searchGroupText,
      searchForGroups,
      hideDiv,
      grouploading,
      checkedGroup,
      closeDropdownIfOpen,
      setFilterGroups,
      goBack,
    };
  },
};
</script>

<style scoped>
.default-btn:hover {
  text-decoration: none;
}
button.el-button {
  height: 36px !important;
  width: 139px !important;
  padding: 13px 24px!important;
  font-size: 13px !important;
}

.generate-report {
  font-size: 1rem;
  color: #fff;
  background-color: #136acd !important ;
  border: none;
  min-width: 7rem;
}

.div-card {
  position: absolute;
  background: white;
  z-index: 1;
  width: 100%;
  top: 70px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  max-height: 400px;
  overflow: scroll;
}

.eachGroup {
  padding: 5px 10px;
  background: #eee;
  border-radius: 25px;
  margin: 0 3px;
}
</style>

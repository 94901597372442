<template>
  <div class="container-fluid">
    <!-- Accounts Table -->
    <div class="mt-2 row">
      <div
        class="container-fluild table-main remove-styles2 remove-border responsiveness"
      >
        <table
          id="table"
          class="table remove-styles mt-0 table-hover table-header-area"
        >
          <thead class="table-header-ar">
            <tr class="font-weight-bold">
              <th>ACCOUNTS</th>
              <th class="text-right"></th>
              <th class="text-right">
                <div class="text-dak s-14">Mar 02, 2025</div>
                <div class="text-dak s-14">to Mar 20, 2025</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Assets Section -->
            <tr v-for="(item, index) in income" :key="index">
              <td>
                <a href="#" class="text-decoration-none fw-400 text-dak s-14">{{
                  item.name
                }}</a>
              </td>
              <td></td>
              <td class="text-right">
                {{ currentUser.currencySymbol }} {{ formatAmount(item.amount) }}
              </td>
            </tr>
            <tr class="table-header-area-main">
              <td class="s-14">
                <div class="text-dak font-weight-bold">Gross Profit</div>
                <div class="s-12 fw-400 text-dak">
                  As a percentage of total income
                </div>
              </td>
              <td></td>
              <td class="text-right text-dak s-14">
                <div class="font-weight-bold">
                  {{ currentUser.currencySymbol }}
                  {{ formatAmount(totalIncome) }}
                </div>
                <div class="s-14 fw-400 text-dak">82.5%</div>
              </td>
            </tr>
          </tbody>
          <div class="mt-2"></div>
          <tbody>
            <!-- Assets Section -->
            <tr v-for="(item, index) in expense" :key="index">
              <td>
                <a
                  href="#"
                  class="text-decoration-none text-dak fw-400 s-14"
                  >{{ item.name }}</a
                >
              </td>
              <td class="text-right">
                <!-- {{ formatAmount(item.amount) }} -->
              </td>
              <td class="text-right">
                {{ currentUser.currencySymbol }}
                {{ formatAmount(item.amount) }}
              </td>
            </tr>
            <tr class="table-header-area-main">
              <td>
                <div class="text-dak s-14 font-weight-bold">Net Profit</div>
                <div class="text-dak s-12">As a percentage of total income</div>
              </td>
              <td class="text-right text-dak font-weight-bold s-14"></td>
              <td class="text-right">
                <div class="text-right text-dak font-weight-bold s-14">
                  {{ currentUser.currencySymbol }}
                  {{ formatAmount(totalExpense) }}
                </div>
                <div class="s-14 fw-400 text-dak">82.5%</div>
              </td>
            </tr>
          </tbody>
          <!-- <tbody>
              <tr
                class="font-weight-bold"
                style="border-bottom: 1px solid rgba(0, 0, 1, 0.2) !important"
              >
                <td class="text-dak font-weight-bold s-14">
                  Total for all account
                </td>
                <td
                  class="text-right text-dak font-weight-bold s-14"
                  style="
                    border-bottom: 1px solid #000000 !important;
                    border-top: 1px solid #000000 !important;
                  "
                >
                  {{ formatAmount(totalDebitAssets + totalDebitLiabilities) }}
                </td>
                <td
                  class="text-right text-dak font-weight-bold s-14"
                  style="
                    border-bottom: 1px solid #000000 !important;
                    border-top: 1px solid #000000 !important;
                  "
                >
                  {{ formatAmount(totalCreditAssets + totalCreditLiabilities) }}
                </td>
              </tr>
            </tbody> -->
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

const store = useStore();

// Sample Data
const expense = ref([
  { name: "Opening Expenses", amount: 20000000.53 },
]);

const income = ref([
  { name: "Income", amount: 20000000.53 },
  { name: "Cost of Goods sold", amount: 20000 },
]);

const currentUser = computed(() => {
  if (store && Object.keys(store.getters.currentUser).length > 0)
    return store.getters.currentUser;
  return "";
});

// Computed Totals
const totalExpense = computed(() =>
  expense.value.reduce((sum, item) => sum + item.amount, 0)
);
const totalIncome = computed(() =>
  income.value.reduce((sum, item) => sum + item.amount, 0)
);
const diffBtwIncomeAndExpenses = computed(() => {
  return totalIncome.value - totalExpense.value;
});

// Format Amount
const formatAmount = (amount) =>
  amount.toLocaleString("en-NG", { minimumFractionDigits: 2 });
</script>

<style scoped>
.table th,
.table td {
  border-top: none;
}
.active {
  background: #ffffff;
  color: #08162e;
  font-weight: 700;
  width: 81px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  /* padding: 15px 0; */
}
.bg-secondary {
  background-color: #f8f9fa !important;
}
button.el-button {
  height: 36px !important;
  width: 139px !important;
  padding: 13px 24px !important;
  font-size: 13px !important;
}
.table-main {
  width: 100% !important;
  box-shadow: 0 0.063rem 0.25rem #02172e45 !important;
  /* border: 0.063rem solid #dde2e6 !important; */
  /* border-radius: 30px !important; */
  text-align: left !important;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem !important;
}

.remove-styles {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remove-styles2 {
  padding-right: 0;
  padding-left: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
table tbody tr,
table thead tr {
  border-bottom: 1px solid rgba(0, 0, 1, 0.2);
}
table tbody tr:first-child {
  border-bottom: none !important;
}
table tbody tr:last-child {
  border-bottom: none !important;
}

.remove-border {
  box-shadow: none !important;
}
.table {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  text-align: left;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem;
}

.table thead th {
  font-weight: 700 !important;
  color: #000000 !important;
  font-size: 14px !important;
}
.table tbody tr {
  font-weight: 400 !important;
  color: #000000 !important;
  font-size: 14px !important;
}

.table-header-area-main {
  background-color: #d4dde4;
  /* background-color: #f4f4f4; */
}

.table-header-area {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

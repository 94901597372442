<template>
  <div class="container-fluid">
    <!-- Title -->
    <div class="row flex-row justify-content-between">
      <div class="mb-4">
        <div class="text-head font-weight-bold h2 py-0 my-0 text-black">
          Trial Balance
        </div>
        <!-- <div @click="goBack">
          <span class="s-18 fw-400 cursor-pointer text-black">
            <img src="../../../assets/goback.png" alt="" /> Go back</span
          >
        </div> -->
      </div>
      <div class="c-pointer">
        <el-dropdown trigger="click" class="w-100">
          <div class="d-flex default-btn text-dark w-100" size="large">
            <span class="mt-1 s-14 text-head" style="color: #0040ff"
              >Export</span
            >
            <div class="mt-0 ml-1">
              <el-icon :size="10" class="el-icon--right primary--text"
                ><ArrowDownBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(bookType, index) in bookTypeList"
                :key="index"
              >
                <a
                  class="no-decoration text-dark"
                  @click="downloadFile(bookType)"
                >
                  {{ bookType.name }}
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="row bg-gray-300 justify-content-center py-3 border-radius-8">
      <div class="col-md-12 pl-4">
        <div class="row align-items-center">
          <div class="col-md-8 col-12">
            <div class="row align-items-center">
              <div class="col-md-4 col-12 mt-1 px-0 mb-3 mb-md-0">
                <div class="col-md-12 px-md-1 fw-400 s-12">As of</div>
                <div class="col-12 px-md-1">
                  <el-select
                    size="small"
                    v-model="selectedYearID"
                    placeholder="Select"
                    class="w-100"
                  >
                    <el-option
                      @click="setSelectedYear"
                      v-for="item in years"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
              </div>
              <div class="col-md-4 col-12 px-0 mb-3 mb-md-0">
                <div class="col-md-12 px-md-1  fw-400 s-12">Date</div>
                <div class="col-12 px-md-1">
                  <el-date-picker
                    v-model="selectedDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
              <div class="col-md-4 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-0 mt-1">
                  <div class="col-md-12 px-md-1  fw-400 s-12">Report type</div>
                  <div class="col-12 px-md-1">
                    <el-select
                      size="small"
                      v-model="selectReportTypeID"
                      placeholder="Select"
                      class="w-100"
                    >
                      <el-option
                        @click="setSelectReportType"
                        v-for="item in reportType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div
              class="col-md-12 mt-4 d-flex justify-content-center justify-content-md-end px-0"
            >
              <el-button
                class="c-pointer w-100"
                :color="primarycolor"
                :loading="loading"
                round
                @click="generateTrialBalance"
              >
                Generate Report
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accounts Table -->
    <div class="mt-5 row">
      <div
        class="container-fluild table-main remove-styles2 remove-border responsiveness"
      >
        <table
          id="table"
          class="table remove-styles mt-0 table-hover table-header-area"
        >
          <thead class="table-header-ar">
            <tr class="font-weight-bold">
              <th>ACCOUNTS</th>
              <th class="text-right">DEBIT</th>
              <th class="text-right">CREDIT</th>
            </tr>
          </thead>
          <tbody>
            <!-- Assets Section -->
            <tr class="table-header-area-main fw-400 s-14">
              <th class="fw-400 text-dak s-14" colspan="3">Assets</th>
            </tr>
            <tr v-for="(asset, index) in assets" :key="index">
              <td>
                <a
                  href="#"
                  class="text-decoration-none fw-500 s-12"
                  style="color: #0b55d4"
                  >{{ asset.name }}</a
                >
              </td>
              <td class="text-right">{{ formatAmount(asset.debitAmount) }}</td>
              <td class="text-right">{{ formatAmount(asset.creditAmount) }}</td>
            </tr>
            <tr class="font-weight-bold">
              <td class="text-dak font-weight-bold s-14">Total Assets</td>
              <td class="text-right text-dak font-weight-bold s-14">
                {{ formatAmount(totalDebitAssets) }}
              </td>
              <td class="text-right text-dak font-weight-bold s-14">
                {{ formatAmount(totalCreditAssets) }}
              </td>
            </tr>
          </tbody>
          <div class="mt-4"></div>
          <tbody>
            <!-- Assets Section -->
            <tr class="table-header-area-main fw-400 s-14">
              <th class="fw-400 text-dak s-14" colspan="3">Liabilities</th>
            </tr>
            <tr v-for="(liability, index) in liabilities" :key="index">
              <td>
                <a
                  href="#"
                  class="text-decoration-none fw-500 s-12"
                  style="color: #0b55d4"
                  >{{ liability.name }}</a
                >
              </td>
              <td class="text-right">
                {{ formatAmount(liability.debitAmount) }}
              </td>
              <td class="text-right">
                {{ formatAmount(liability.creditAmount) }}
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td class="text-dak font-weight-bold s-14">Total Liabilities</td>
              <td class="text-right text-dak font-weight-bold s-14">
                {{ formatAmount(totalDebitLiabilities) }}
              </td>
              <td class="text-right text-dak font-weight-bold s-14">
                {{ formatAmount(totalCreditLiabilities) }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <!-- Assets Section -->
            <tr
              class="font-weight-bold"
              style="border-bottom: 1px solid rgba(0, 0, 1, 0.2) !important"
            >
              <td class="text-dak font-weight-bold s-14">
                Total for all account
              </td>
              <td
                class="text-right text-dak font-weight-bold s-14"
                style="
                  border-bottom: 1px solid #000000 !important;
                  border-top: 1px solid #000000 !important;
                "
              >
                {{ formatAmount(totalDebitAssets + totalDebitLiabilities) }}
              </td>
              <td
                class="text-right text-dak font-weight-bold s-14"
                style="
                  border-bottom: 1px solid #000000 !important;
                  border-top: 1px solid #000000 !important;
                "
              >
                {{ formatAmount(totalCreditAssets + totalCreditLiabilities) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import exportService from "../../../services/exportFile/exportservice";

// Date & Filters
const selectedYear = ref(2025);
const selectedYearID = ref(null);
const selectReportTypeID = ref(null);
const years = ref([2023, 2024, 2025, 2026]);
const selectedDate = ref(new Date(Date.now()));
const loading = ref(false);
const selectReportType = ref({});
const primarycolor = inject("primarycolor");
const reportType = ref([
  { name: "Accrual (Paid and Unpaid)", id: 1 },
  { name: "Accrual", id: 2 },
  { name: "Cash Basis", id: 3 },
]);

const displayTitle = ref(false);
const fileName = ref("First Timers(M) Report");
const bookTypeList = ref([
  { name: "xlsx" },
  { name: "csv" },
  { name: "txt" },
  { name: "" },
]);
// const selectedFileType = ref({});
const fileHeaderToExport = ref([]);
const fileToExport = ref([]);

const downloadFile = async (item) => {
  if (item.name === "pdf") {
    displayTitle.value = true;
    await nextTick();
    exportService.downLoadExcel(
      item.name,
      document.getElementById("element-to-print"),
      fileName.value,
      fileHeaderToExport.value,
      fileToExport.value
    );
  } else {
    displayTitle.value = false;
    exportService.downLoadExcel(
      item.name,
      document.getElementById("element-to-print"),
      fileName.value,
      fileHeaderToExport.value,
      fileToExport.value
    );
  }
};

const setSelectedYear = () => {
  selectedYear.value = years.value.find((i) => i === selectedYearID.value);
};
const setSelectReportType = () => {
  selectReportType.value = reportType.value.find(
    (i) => i.id === selectReportTypeID.value
  );
};

// Sample Data
const assets = ref([
  { name: "Courtney Henry", creditAmount: 20000, debitAmount: 15000 },
  { name: "Annette Black", creditAmount: 20000, debitAmount: 15000 },
  { name: "Marvin McKinney", creditAmount: 20000, debitAmount: 15000 },
  { name: "Floyd Miles", creditAmount: 20000, debitAmount: 15000 },
  { name: "Jerome Bell", creditAmount: 20000, debitAmount: 15000 },
  { name: "Darlene Robertson", creditAmount: 205000, debitAmount: 15000 },
  { name: "Cody Fisher", creditAmount: 20000, debitAmount: 15000 },
  { name: "Dianne Russell", creditAmount: 20000, debitAmount: 15000 },
  { name: "Arlene McCoy", creditAmount: 20000, debitAmount: 15000 },
]);

const liabilities = ref([
  { name: "Sam Loko", creditAmount: 20000, debitAmount: 15000 },
  { name: "Sam Loko", creditAmount: 20000, debitAmount: 15000 },
  { name: "Sam Loko", creditAmount: 20000, debitAmount: 15000 },
  { name: "Sam Loko", creditAmount: 20000, debitAmount: 15000 },
  { name: "Sam Loko", creditAmount: 20000, debitAmount: 15000 },
]);

// Computed Totals
const totalDebitAssets = computed(() =>
  assets.value.reduce((sum, item) => sum + item.debitAmount, 0)
);
const totalCreditAssets = computed(() =>
  assets.value.reduce((sum, item) => sum + item.creditAmount, 0)
);
const totalDebitLiabilities = computed(() =>
  liabilities.value.reduce((sum, item) => sum + item.debitAmount, 0)
);
const totalCreditLiabilities = computed(() =>
  liabilities.value.reduce((sum, item) => sum + item.creditAmount, 0)
);

// Format Amount
const formatAmount = (amount) =>
  amount.toLocaleString("en-NG", { minimumFractionDigits: 2 });
</script>

<style scoped>
.table th,
.table td {
  border-top: none;
}
.bg-secondary {
  background-color: #f8f9fa !important;
}
button.el-button {
  height: 36px !important;
  width: 139px !important;
  padding: 13px 24px !important;
  font-size: 13px !important;
}
.table-main {
  width: 100% !important;
  box-shadow: 0 0.063rem 0.25rem #02172e45 !important;
  /* border: 0.063rem solid #dde2e6 !important; */
  /* border-radius: 30px !important; */
  text-align: left !important;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem !important;
}

.remove-styles {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remove-styles2 {
  padding-right: 0;
  padding-left: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
table tbody tr,
table thead tr {
  border-bottom: 1px solid rgba(0, 0, 1, 0.2);
}
table tbody tr:first-child {
  border-bottom: none !important;
}
table tbody tr:last-child {
  border-bottom: none !important;
}

.remove-border {
  box-shadow: none !important;
}
.table {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  text-align: left;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem;
}

.table thead th {
  font-weight: 700 !important;
  color: #000000 !important;
  font-size: 14px !important;
}
.table tbody tr {
  font-weight: 400 !important;
  color: #000000 !important;
  font-size: 14px !important;
}

.table-header-area-main {
  background-color: #d4dde4;
  /* background-color: #f4f4f4; */
}

.table-header-area {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

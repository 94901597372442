<template>
  <div class="container-fluid">
    <!-- header area -->
    <div class="row flex-row justify-content-between">
      <div class="mb-4">
        <div class="text-head font-weight-bold h2 py-0 my-0 text-black">
          First Timers Report
        </div>
        <!-- <div @click="goBack">
          <span class="s-18 fw-400 cursor-pointer text-black">
            <img src="../../../assets/goback.png" alt="" /> Go back</span
          >
        </div> -->
      </div>
      <div class="c-pointer">
        <el-dropdown trigger="click" class="w-100">
          <div class="d-flex default-btn text-dark w-100" size="large">
            <span class="mt-1 s-14 text-head" style="color: #0040ff"
              >Export</span
            >
            <div class="mt-0 ml-1">
              <el-icon :size="10" class="el-icon--right primary--text"
                ><ArrowDownBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(bookType, index) in bookTypeList"
                :key="index"
              >
                <a
                  class="no-decoration text-dark"
                  @click="downloadFile(bookType)"
                >
                  {{ bookType.name }}
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <!--end of header area -->
    <!-- date area -->
    <div class="row bg-gray-300 justify-content-center py-4 border-radius-8">
      <div class="col-md-12">
        <div class="row align-items-center">
          <div class="col-md-8 col-12">
            <div class="row align-items-center">
              <div class="col-md-3 col-12">
                <div class="col-12 px-0 s-14 fw-400 text-dak">Date Range</div>
              </div>
              <div class="col-md-3 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-md-0">
                  <el-date-picker
                    v-model="startDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
              <div class="col-md-3 col-12 px-0 mb-3 mb-md-0">
                <div class="col-12 px-md-0">
                  <el-date-picker
                    v-model="endDate"
                    type="date"
                    format="DD/MM/YYYY"
                    class="w-100 calendar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="col-md-12 d-flex justify-content-end px-0">
              <el-button
                class="c-pointer report-button w-100"
                :color="primarycolor"
                :loading="loading"
                round
                @click="generateReport"
              >
                Generate Report
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end of date area -->
  <div id="element-to-print">
    <div
      class="container-fluid d-flex justify-content-center my-2"
      v-if="displayTitle"
    >
      <div class="head-text">First Timer Report</div>
    </div>
    <!-- chart area -->
    <div
      class="chart container-fluid"
      :class="
        firstTimerInChurch && firstTimerInChurch.length > 0 ? 'graph-area' : ''
      "
    >
      <div class="row">
        <div class="chart1 col-12 col-md-6">
          <ByGenderChart
            domId="chart"
            title="By Gender"
            distance="5"
            :titleMargin="10"
            :summary="data"
          />
        </div>
        <div class="chart1 col-12 col-md-6">
          <ByGenderChart
            domId="chartid"
            title="Marital Status"
            distance="5"
            :titleMargin="10"
            :summary="maritalChartInfo"
          />
        </div>
      </div>
    </div>
    <!--end of chart area -->

    <section class="container-fluid">
      <!-- table header -->
      <div v-if="firstTimerInChurch.length > 0" class="row">
        <div
          class="col-md-12 px-0 d-flex justify-content-end"
          v-if="firstTimerInChurch.length > 0 || searchText !== ''"
        >
          <div class="col-md-4 col-12 px-0 mt-5" v-if="!displayTitle">
            <el-input
              v-model="searchText"
              size="small"
              placeholder="Search..."
              :suffix-icon="Search"
            />
          </div>
        </div>
        <div
          class="container-fluid mt-2 table-main remove-styles2 remove-border responsiveness"
        >
          <table
            id="table"
            class="table remove-styles mt-0 table-hover table-header-area"
          >
            <thead class="table-header-area-main">
              <tr
                class="text-capitalize text-nowrap font-weight-bold"
                style="border-bottom: 0; font-size: medium"
              >
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Home Address</th>
                <th scope="col">Gender</th>
                <th scope="col">Current Status</th>
                <th
                  scope="col"
                  v-for="(item, index) in dynamicCustomFields"
                  :key="index"
                >
                  {{ item.label }}
                </th>
              </tr>
            </thead>
            <tbody class="small-text font-weight-bold text-nowrap">
              <tr
                v-for="(firstTimer, index) in filteredAccount"
                :key="index"
              >
                <td>{{ firstTimer.firstName }}</td>
                <td>{{ firstTimer.lastName }}</td>
                <td>{{ firstTimer.mobilePhone }}</td>
                <td>{{ firstTimer.email }}</td>
                <td>{{ firstTimer.homeAddress }}</td>
                <td>{{ firstTimer.gender }}</td>
                <td>{{ firstTimer.status }}</td>
                <td
                  v-show="firstTimer.customAttributeData.length > 0"
                  v-for="(item, index) in dynamicCustomFields"
                  :key="index"
                >
                  {{
                    getMemberCustomAttributeData(
                      firstTimer.customAttributeData,
                      item
                    )
                  }}
                </td>
                <td
                  v-show="firstTimer.customAttributeData.length === 0"
                  v-for="(item, index) in dynamicCustomFields.length"
                  :key="index"
                >
                  {{ "--" }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-foot d-flex justify-content-end mt-3">
            <!-- mt-n3" -->
            <!-- <PaginationButtons /> -->
          </div>
        </div>
      </div>
      <!--end table header  -->
    </section>
  </div>
</template>

<script>
import { ref, inject, nextTick, computed } from "vue";
import ByGenderChart from "@/components/charts/PieChart.vue";
import axios from "@/gateway/backendapi";
import router from "../../../router";
import { Search } from "@element-plus/icons-vue";
import dateFormatter from "../../../services/dates/dateformatter";
import printJS from "print-js";
import exportService from "../../../services/exportFile/exportservice";
import allCustomFields from "../../../services/customfield/customField";
// import { Calendar } from '@element-plus/icons-vue'

export default {
  components: {
    ByGenderChart,
  },
  setup() {
    const startDate = ref(new Date().setFullYear(new Date().getFullYear() - 1));
    const endDate = ref(new Date(Date.now()));
    const searchText = ref("");
    const firstTimerInChurch = ref([]);
    const genderChartResult = ref([]);
    const data = ref([]);
    const primarycolor = inject("primarycolor");
    const maritalChartInfo = ref([]);
    const showExport = ref(false);
    const loading = ref(false);
    const displayTitle = ref(false);
    const fileName = ref("First Timers(M) Report");
    const bookTypeList = ref([
      { name: "xlsx" },
      { name: "csv" },
      { name: "txt" },
      { name: "" },
    ]);
    const selectedFileType = ref({});
    const fileHeaderToExport = ref([]);
    const fileToExport = ref([]);
    const dynamicCustomFields = ref([]);

    const goBack = () => {
      router.go(-1);
    };

    const filteredAccount = computed(() => {
      if (!searchText.value.trim()) {
        return firstTimerInChurch.value;
      }
      return firstTimerInChurch.value.filter((item) =>
        Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(searchText.value.toLowerCase())
      );
    });

    const generateReport = () => {
      loading.value = true;
      axios
        .get(
          `/api/Reports/people/getFirstTimersReport?startDate=${new Date(
            startDate.value
          ).toLocaleDateString("en-US")}&endDate=${new Date(
            endDate.value
          ).toLocaleDateString("en-US")}`
        )
        .then((res) => {
          firstTimerInChurch.value = res.data;
          data.value = getGenderChart(res.data);
          maritalChartInfo.value = maritalChart(res.data);
          /* function to call service and populate table */
          setTimeout(() => {
            fileHeaderToExport.value = exportService.tableHeaderToJson(
              document.getElementsByTagName("th")
            );
            fileToExport.value = exportService.tableToJson(
              document.getElementById("table")
            );
          }, 1000);
          /* End function to call service and populate table */
          loading.value = false;
        })

        .catch((err) => {
          console.log(err);

          loading.value = false;
        });
    };

    const getGenderChart = (arr) => {
      return [
        getSumOfItems(arr, "gender", "Male"),
        getSumOfItems(arr, "gender", "Female"),
      ];
    };

    const maritalChart = (arr) => {
      return [
        getSumOfItems(arr, "maritalStatus", "Married"),
        getSumOfItems(arr, "maritalStatus", "Single"),
      ];
    };

    const getSumOfItems = (arr, key, value) => {
      return {
        name: value,
        value: firstTimerInChurch.value.filter((i) => i[key] === value).length,
      };
    };

    /* Code For Exporting File */
    // const downloadFile = (item) => {
    //   if (item.name === "pdf") {
    //     displayTitle.value = true;
    //   } else {
    //     displayTitle.value = false;
    //   }
    //   exportService.downLoadExcel(
    //     item.name,
    //     document.getElementById("element-to-print"),
    //     fileName.value,
    //     fileHeaderToExport.value,
    //     fileToExport.value
    //   );
    // };
    const downloadFile = async (item) => {
      if (item.name === "pdf") {
        displayTitle.value = true;
        await nextTick();
        exportService.downLoadExcel(
          item.name,
          document.getElementById("element-to-print"),
          fileName.value,
          fileHeaderToExport.value,
          fileToExport.value
        );
      } else {
        displayTitle.value = false;
        exportService.downLoadExcel(
          item.name,
          document.getElementById("element-to-print"),
          fileName.value,
          fileHeaderToExport.value,
          fileToExport.value
        );
      }
    };
    /* End Code For Exporting File */

    const formatDate = (activityDate) => {
      return dateFormatter.monthDayTime(activityDate);
    };

    const getAllCustomFields = async () => {
      try {
        let data = await allCustomFields.allCustomFields();
        dynamicCustomFields.value = data.filter((i) => i.entityType === 1);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCustomFields();

    const getMemberCustomAttributeData = (
      memberCustomData,
      singleCustomField
    ) => {
      if (memberCustomData && memberCustomData.length === 0) return "--";
      const findData = memberCustomData.findIndex(
        (i) => i.customAttribute.id === singleCustomField.id
      );
      if (findData >= 0) return memberCustomData[findData].data;
      return "--";
    };

    return {
      // Calendar,
      startDate,
      Search,
      filteredAccount,
      searchText,
      primarycolor,
      endDate,
      firstTimerInChurch,
      generateReport,
      formatDate,
      loading,
      genderChartResult,
      data,
      maritalChartInfo,
      displayTitle,
      fileName,
      downloadFile,
      goBack,
      showExport,
      bookTypeList,
      printJS,
      selectedFileType,
      dynamicCustomFields,
      getMemberCustomAttributeData,
    };
  },
};
</script>

<style scoped>
button.el-button {
  height: 36px !important;
  width: 139px !important;
  padding: 13px 24px !important;
  font-size: 13px !important;
}
.default-btn {
  font-weight: 600;
  white-space: initial;
  font-size: 1rem;
  border-radius: 3rem;
  /* border: 1px solid #002044; */
  padding: 0.5rem 1.25rem;
  width: auto;
  /* border:none; */
  /* outline: transparent !important; */
  max-height: 40px;
  /* background: #6c757d47 !important; */
  color: #000;
  text-decoration: none;
  min-width: 121px;
}

.default-btn:hover {
  text-decoration: none;
}

.generate-report {
  font-size: 1rem;
  color: #fff;
  background-color: #136acd !important ;
  border: none;
  min-width: 7rem;
}

.heading-text {
  font: normal normal 800 1.5rem Nunito sans;
}

.bg-area {
  background-color: #ebeff4;
  border-radius: 0.5rem;
  padding: 0.2rem 0 1.2rem 0;
}

.table {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  text-align: left;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem;
}

.table thead th {
  font-weight: 400 !important;
  color: #000000 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.table tbody tr {
  font-weight: 400 !important;
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.table-header-area-main {
  background-color: #f4f4f4;
}

.table-header-area {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.table-main {
  width: 100% !important;
  box-shadow: 0 0.063rem 0.25rem #02172e45 !important;
  border: 0.063rem solid #dde2e6 !important;
  /* border-radius: 30px !important; */
  text-align: left !important;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem !important;
}

.remove-styles {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remove-styles2 {
  padding-right: 0;
  padding-left: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.remove-border {
  box-shadow: none !important;
}

.graph-area {
  border: 1px solid #dde2e6;
  border-radius: 0.5rem;
  padding: 1rem 0rem;
  margin: 2rem 0rem !important;
  width: 100% !important;
  box-shadow: 0 0.063rem 0.25rem #02172e45;
}

.responsiveness {
  max-width: 100%;
  /* overflow-y: scroll; */
  overflow-x: scroll;
}

/* .move-enter-active {
  animation: move-in .8s;
}
.move-leave-active {
  animation: move-in .8s reverse;
}
@keyframes move-in {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }

} */
</style>

<template>
  <div class="container-fluid px-0">
    <div class="row mt-4 border-radius-border-12">
      <div class="col-md-12 px-0 col-12 text-center">
        <div
          class="row tabl border-0 report d-flex justify-content-between p-2 p-md-4"
        >
          <div class="col-md-5 pl-0 pr-4 col-12">
            <h4 class="ju header4" style="color: #292929;">Membership</h4>
            <p class="ju paragraph1 text-black fw-300">
              Generate your membership report and know the progress of your
              church members.
            </p>
          </div>
          <div class="col-md-7 col-12">
            <div
              class="row d-md-flex justify-content-md-between d-flex justify-content-between"
            >
              <router-link to="/tenant/reports/membershipreport" class="linked"
                ><h4
                  class="ju header4 d-md-flex s-20 justify-content-md-start"
                  style="color: #0b55d4"
                >
                  Membership Report
                </h4></router-link
              >
              <router-link to="/tenant/reports/membershipreport">
                <span
                  ><el-icon :color="primarycolor" :size="18"
                    ><ArrowRightBold /></el-icon
                ></span>
              </router-link>
            </div>
            <div class="row">
              <p class="ju mb-4 paragraph1 fw-400">
                This reports provides a detailed report of the Membership in
                your ministry.
              </p>
            </div>
            <div class="row">
              <hr class="w-100" />
            </div>
          </div>

          <div class="col-md-5 px-0 col-12"></div>
          <div class="col-md-7 col-12 mt-2">
            <div
              class="row d-md-flex justify-content-md-between d-flex justify-content-between"
            >
              <router-link to="/tenant/reports/firsttimersreport" class="linked"
                ><h4
                  class="ju header4 d-md-flex s-20 justify-content-md-start"
                  style="color: #0b55d4"
                >
                  First Timer Report
                </h4></router-link
              >
              <router-link to="/tenant/reports/firsttimersreport">
                <span
                  ><el-icon :color="primarycolor" :size="18"
                    ><ArrowRightBold /></el-icon></span
              ></router-link>
            </div>
            <div class="row">
              <p class="ju mb-4 paragraph1 fw-400">
                This reports provides a detailed report of the first timers in
                your ministry.
              </p>
            </div>
            <div class="row">
              <hr class="w-100" />
            </div>
          </div>

          <div class="col-md-5 px-0 col-12"></div>
          <div class="col-md-7 col-12 mt-2">
            <div
              class="row d-md-flex justify-content-md-between d-flex justify-content-between"
            >
              <router-link to="/tenant/reports/newconvertreport" class="linked"
                ><h4
                  class="ju header4 d-md-flex s-20 justify-content-md-start"
                  style="color: #0b55d4"
                >
                  New Convert
                </h4></router-link
              >
              <router-link to="/tenant/reports/newconvertreport">
                <span
                  ><el-icon :color="primarycolor" :size="18"
                    ><ArrowRightBold /></el-icon
                ></span></router-link>
            </div>
            <div class="row">
              <p class="ju mb-4 paragraph1 fw-400">
                This reports provides a detailed report of new converts in your
                ministry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 mb-5 border-radius-border-12">
      <div class="col-md-12 col-12 px-0 text-center">
        <div
          class="row tabl border-0 report d-flex justify-content-between p-2 p-md-4"
        >
          <div class="col-md-5 pl-0 pr-4 col-12">
            <h4 class="ju header4" style="color: #292929;">Celebrations</h4>
            <p class="ju paragraph1 text-black fw-300">
              Send your Members Automated message on thier birthday and wedding
              event
            </p>
          </div>
          <div class="col-md-7 col-12">
            <div
              class="row d-md-flex justify-content-md-between d-flex justify-content-between"
            >
              <router-link
                to="/tenant/reports/celebrationsreport"
                class="linked"
                ><h4
                  class="ju header4 d-md-flex s-20 justify-content-md-start"
                  style="color: #0b55d4"
                >
                  Birthday Report
                </h4></router-link
              >
              <router-link to="/tenant/reports/celebrationsreport"
                ><span
                  ><el-icon :color="primarycolor" :size="18"
                    ><ArrowRightBold /></el-icon
                ></span></router-link>
            </div>
            <div class="row">
              <p class="ju mb-4 paragraph1 fw-400">
                This reports provides a detailed list of all the church contacts
                celebrating their birthday within the selected period
              </p>
            </div>
            <div class="row">
              <hr class="w-100" />
            </div>
          </div>
          <div class="col-md-5 px-0 col-12"></div>
          <div class="col-md-7 col-12 mt-2">
            <div
              class="row d-md-flex justify-content-md-between d-flex justify-content-between"
            >
              <router-link
                to="/tenant/reports/weddinganniversaryreport"
                class="linked"
                ><h4
                  class="ju header4 d-md-flex s-20 justify-content-md-start"
                  style="color: #0b55d4"
                >
                  Wedding Anniversary Report
                </h4></router-link
              >
              <router-link to="/tenant/reports/weddinganniversaryreport"
                ><span
                  ><el-icon :color="primarycolor" :size="18"
                    ><ArrowRightBold /></el-icon
                ></span></router-link>
            </div>
            <div class="row">
              <p class="ju mb-4 paragraph1 fw-400">
                This reports provides a detailed list of all the church contacts
                celebrating their weddings within the selected period.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  setup() {
    const primarycolor = inject("primarycolor");

    return {
      primarycolor,
    };
  },
};
</script>

<style scoped>
.report {
  width: 100%;
  margin: auto;
}
.ju {
  text-align: start;
}
.header4 {
  font-size: 24px;
  font-weight: 500;
}
.paragraph1 {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 100%; */
  color: #787878;
  /* background-color: #fff; */
}
.rounded {
  border-color: #b0b2b5 !important;
  border-radius: 15px !important;
}
.linked {
  text-decoration: none !important;
}
.linked:hover {
  text-decoration: underline !important;
}
</style>
